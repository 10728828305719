@use '@angular/material' as mat;
//@use 'style/dark_theme' as dark;

@use '@ft/core/style' as ft;

.ft-app-loader {
  $ftm-loader-size: 12px !default;

  .ftm-loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .ftm-loader div {
    position: absolute;
    width: $ftm-loader-size;
    height: $ftm-loader-size;
    background-color: mat.get-color-from-palette(ft.$ft-primary-palette, 800);
    border-radius: 50%;
    animation: ftm-loader 1.2s linear infinite;
  }

  .ftm-loader div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }

  .ftm-loader div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }

  .ftm-loader div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }

  .ftm-loader div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }

  .ftm-loader div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }

  .ftm-loader div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }

  .ftm-loader div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }

  .ftm-loader div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }

  .ftm-loader div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }

  .ftm-loader div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }

  .ftm-loader div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }

  .ftm-loader div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }

  @keyframes ftm-loader {
    0%, 20%, 80%, 100% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1.3);
    }
  }

}