/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
//@use 'style/dark_theme' as dark;

@use '@ft/core/style' as ft with (
  $ft-primary-palette: mat.define-palette(mat.$teal-palette, 800),
  $ft-accent-palette: mat.define-palette(mat.$blue-gray-palette, 600)
  //$body-background-color: dark.$ftm-body-background-color
);
@use "@ft/core/style/components/dialog" as ftDialog;

@import 'xterm/css/xterm.css';
@import 'dragula/dist/dragula.css';
@import 'style/loader';

// applying core themes
@include ft.load-theme();

// xterm
body > canvas {
  display: none;
}

// toolbar related
ft-app-toolbar .toolbar-tools .button-separated {
  margin-left: ft.$ft-large-spacer;
}

// @include dark.apply-dark-theme();


.monaco-dialog {
  @include ftDialog.base-dialog-style();

  min-height: 90%;
  max-height: 90%;
  min-width: 90%;
  max-width: 90%;
}

.services-table {
  .mat-column-id, .mat-column-groupUser {
    min-width: 120px;
    max-width: 160px;
    width: 20%;
  }

  .mat-column-unitFileState, .mat-column-loadState, .mat-column-activeState, .mat-column-subState,
  .mat-column-execMainPID {
    max-width: 100px;
  }
}

.demo-use-overlay {
  position: absolute;
  bottom: ft.$ft-small-spacer*2;
  right: 165px;
  font-size: 32px;
  color: var(--ft-warn-color);
  opacity: .4;
  z-index: 9995;
  text-shadow: 3px 1px 2px rgba(0, 0, 0, 0.2);
}
